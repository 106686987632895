import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/hall-:id/:stand?',
    name: 'Hall',
    props: true,
    component: () => import(/* webpackChunkName: "hall" */ '../views/Hall3.vue')
  },
  {
    path: '/hallold-:id/:stand?',
    name: 'Hall2',
    props: true,
    component: () => import(/* webpackChunkName: "hall" */ '../views/Hall.vue')
  },
  {
    path: '/hall3-:id/:stand?',
    name: 'Hall3',
    props: true,
    component: () => import(/* webpackChunkName: "hall" */ '../views/Hall3.vue')
  },
  {
    path: '/stand-:stand/:mini?',
    name: 'Stand',
    props: true,
    component: () => import(/* webpackChunkName: "hall" */ '../views/Standview3.vue')
  },

  {
    path: '/Datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "ds" */ '../views/Datenschutz.vue')
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
